import type {ApolloQueryResult, FetchResult} from '@apollo/client/core';
import type {GraphQLError} from 'graphql';

import {Logger} from '../shared/utils/logger.util';
import type {GraphqlErrorInterface, SingleFetchResult} from './graphql.interface';

export class GraphqlError extends Error implements GraphqlErrorInterface {
    meta: GraphqlErrorInterface['meta'];
    extensions: GraphqlErrorInterface['extensions'];
    message: GraphqlErrorInterface['message'];
    path: GraphqlErrorInterface['path'];

    constructor(error: GraphQLError | GraphqlErrorInterface) {
        super('override below');
        Object.assign(this, error, {
            message: GraphqlError.getMessage(error),
        });
    }

    static getMessage(error: GraphQLError | GraphqlError | GraphqlErrorInterface): string {
        if (error instanceof GraphqlError) {
            return error.message;
        }

        if (error.path === undefined) {
            return error.message;
        }

        return `${error.path.join(', ')}: ${error.message}`;
    }
}

export class GraphqlErrors extends Error {
    errors: Array<GraphqlError>;

    constructor(errors: ReadonlyArray<GraphQLError | GraphqlError | GraphqlErrorInterface>) {
        super(errors.map(e => GraphqlError.getMessage(e)).join('\n'));
        this.errors = errors.map(e => new GraphqlError(e));
    }

    hasErrorCode(code: string): boolean {
        return this.errors.some(e => e.meta?.code === code);
    }

    getUnexpected(): Array<GraphqlError> {
        return this.errors.filter(e => e.meta?.expected !== true);
    }

    isUnexpected(): boolean {
        return this.errors.some(e => e.meta?.expected !== true);
    }
}

/**
 * Throws an error if the GraphQL result contains an `errors` property. Returns the data otherwise.
 */
export function graphqlErrorHandler<T = any>(result: FetchResult<T> | SingleFetchResult<T>): T {
    if (result.errors !== undefined) {
        const error = new GraphqlErrors(result.errors);
        const unexpected = error.getUnexpected();

        if (unexpected.length > 0) {
            setTimeout(() => {
                // Delay to prevent deduping more granular error message
                Logger.error({
                    message: 'Unexpected GraphQL error(s)',
                    error: new GraphqlErrors(unexpected),
                });
            }, 200);
        }

        throw error;
    }

    return result.data!;
}

type ApolloResponse = ApolloQueryResult<any> | FetchResult<any>;

export function apolloGraphqlErrorHandlerSingle<T = any>(result: ApolloResponse): T {
    const data = graphqlErrorHandler(result);

    return Object.values(data)[0] as T;
}
