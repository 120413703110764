import {HttpErrorResponse} from '@angular/common/http';
import {ApolloError} from '@apollo/client/core';
import {captureException, captureMessage} from '@sentry/browser';

import {environment} from '../../../environments/environment';
import {GraphqlErrors} from '../../grapqhl/graphql-error-handler';
import {objectContainsProperty} from './object.util';

interface ErrorCodeDeterminator {
    code: string;
    log: boolean;
}

type ExtractedError = {
    context: Record<string, unknown> | null;
} & ({
    error: Error | ErrorEvent;
} | {
    message: string;
});

export class Logger {

    static error(input: string | {
        error: unknown;
        info?: any;
        message: string;
        report?: boolean;
    }): void {
        // eslint-disable-next-line no-console
        console.error(input);

        if (!environment.sentry.enabled) {
            return;
        }

        if (typeof input === 'string') {
            captureMessage(input, 'error');
            return;
        }

        if (input.report === false) {
            return;
        }

        const extracted = this.extractError(input.error ?? input.message);
        if (extracted === null) {
            return;
        }

        if ('message' in extracted) {
            captureMessage(input.message, {
                extra: {
                    info: input.info,
                    context: extracted.context,
                },
                level: 'error',
            });
        } else {
            captureException(input.error, {
                extra: {
                    message: input.message,
                    info: input.info,
                    context: extracted.context,
                },
            });
        }
    }

    static errorWrap(error: Error): void;
    static errorWrap(message: string, info?: any): (err: Error) => void;
    static errorWrap(
        errorOrMessage: Error | string,
        info?: any,
    ): ((err: Error) => void) | undefined {
        if (errorOrMessage instanceof Error) {
            Logger.error({
                error: errorOrMessage,
                message: errorOrMessage.message,
            });
            return;
        }

        return error => {
            Logger.error({
                error,
                info,
                message: errorOrMessage,
            });
        };
    }

    static handleRequestError({defaultCode, error, info, message}: {
        defaultCode?: string;
        error: Error;
        info?: any;
        message: string;
    }): string {
        let result: string | null = null;
        let log = true;

        function assignResultAndLog(input?: ErrorCodeDeterminator | null): void {
            if (input == null) {
                return;
            }

            result = input.code;
            log = input.log;
        }

        if (error instanceof HttpErrorResponse) {
             assignResultAndLog(this.getErrorCodeFromHttpErrorResponse(error));
        } else if (error instanceof ApolloError) {
            if (error.networkError !== null) {
                if (error.networkError instanceof HttpErrorResponse) {
                    assignResultAndLog(this.getErrorCodeFromHttpErrorResponse(error.networkError));
                }
            }
        } else if (error instanceof GraphqlErrors) {
            log = error.isUnexpected();

            if (error.hasErrorCode('Unauthenticated')) {
                result = 'Error.Unauthenticated';
            } else if (error.hasErrorCode('Unauthorized')) {
                result = 'Error.Unauthorized';
            }
        }

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (log) {
            Logger.error({
                error,
                info,
                message,
            });
        } else {
            // eslint-disable-next-line no-console
            console.error({
                error,
                info,
                message,
            });
        }

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        return result ?? defaultCode ?? 'Error.Unknown';
    }

    static warn(message: string, info: Record<string, any>): void {
        // eslint-disable-next-line no-console
        console.warn(message, info);
        captureMessage(message, {
            extra: {
                ...info,
            },
            level: 'warning',
        });
    }

    static extractError(error: unknown): ExtractedError | null {
        // Try to unwrap zone.js error.
        // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
        if (objectContainsProperty(error, 'ngOriginalError')
            && error.ngOriginalError instanceof Error) {
            error = error.ngOriginalError;
        }

        // We can handle messages and Error objects directly.
        if (typeof error === 'string') {
            return {
                message: error,
                context: null,
            };
        }

        // If it's http module error, extract as much information from it as we can.
        if (error instanceof HttpErrorResponse) {
            const context = {
                httpMessage: error.message,
                httpStatus: error.status,
                httpStatusText: error.statusText,
                httpUrl: error.url,
            };

            // The `error` property of http exception can be either an `Error` object, which we can
            // use directly...
            if (error.error instanceof Error) {
                return {
                    error: error.error,
                    context,
                };
            }

            // ... or an`ErrorEvent`, which can provide us with the message but no stack...
            if (error.error instanceof ErrorEvent) {
                return {
                    error: error.error,
                    context,
                };
            }

            // ...or the request body itself, which we can use as a message instead.
            if (typeof error.error === 'string') {
                return {
                    message: error.error,
                    context,
                };
            }

            // If we don't have any detailed information, fallback to the request message itself.
            return {
                message: error.message,
                context: {
                    http: context,
                    errorResponse: error.error,
                },
            };
        }

        if (error instanceof Error) {
            return {
                error,
                context: null,
            };
        }

        return null;
    }

    private static getErrorCodeFromHttpErrorResponse(
        error: HttpErrorResponse,
    ): ErrorCodeDeterminator | null {
        if (error.status === 0) {
            return {
                code: navigator.onLine ? 'Error.Cannot reach' : 'Error.Offline',
                log: navigator.onLine,
            };
        }

        return null;
    }
}
