import type {Environment} from './environment.interface';
import {applyGeneratedEnvironment} from './environment.utils';

export const environment: Environment = {
    api: 'https://api.calculator.salesmakers.be',
    checkForUpdates: true,
    environment: 'production',
    i18n: {
        format: 'json',
        prettifyMissingTranslations: true,
    },
    production: true,
    sentry: {
        enabled: true,
        dsn: 'https://7f219fee9eed46cd9636711921d974c1@o406419.ingest.sentry.io/6127651',
    },
    uppy: {
        debug: false,
    },
};

applyGeneratedEnvironment(environment);
