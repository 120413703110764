import type {NotUndefined} from './guards.util';
import {isNotUndefined} from './guards.util';

export function removeUndefined<V = any>(
    object: Record<string, V>,
): Record<string, NotUndefined<V>> {
    return Object.entries(object).reduce<Record<string, NotUndefined<V>>>((acc, [key, value]) => {
        if (isNotUndefined(value)) {
            acc[key] = value;
        }

        return acc;
    }, {});
}

function messageFormatFlatPrivate(
    object: Record<string, any>,
    res: Record<string, any>,
    prefix?: string,
): Record<string, any> {
    for (const k of Object.keys(object)) {
        const key = prefix === undefined ? k : `${prefix}_${k}`;
        const val = object[k];

        if (val != null && typeof val === 'object') {
            messageFormatFlatPrivate(val, res, key);
        } else {
            res[key] = val;
        }
    }

    return res;
}

export function messageFormatFlat(
    object: Record<string, any>,
): Record<string, any> {
    return messageFormatFlatPrivate(object, {});
}

export function objectContainsProperty<Key extends string>(
    obj: unknown,
    key: Key,
): obj is Record<Key, unknown> {
    return typeof obj === 'object' && obj !== null && key in obj;
}
