import type {Breadcrumb} from '@sentry/browser';
import {
    breadcrumbsIntegration,
    dedupeIntegration,
    extraErrorDataIntegration,
    functionToStringIntegration,
    globalHandlersIntegration,
    httpContextIntegration,
    inboundFiltersIntegration,
    init,
    linkedErrorsIntegration,
    reportingObserverIntegration,
} from '@sentry/browser';

import {environment} from '../../../environments/environment';
import {GraphqlErrors} from '../../grapqhl/graphql-error-handler';

export class SentryErrorHandler {

    private static initialized = false;

    private constructor() {
        // Do not allow construction
    }

    static init() {
        if (this.initialized) {
            return;
        }

        if (environment.sentry.enabled) {
            const objectDepth = 10;

            init({
                beforeBreadcrumb: (breadcrumb, hint) => {
                    return this.modifyBreadcrumb(breadcrumb, hint);
                },
                beforeSend: (event, hint) => {
                    const exception = hint.originalException;

                    if (exception instanceof GraphqlErrors) {
                        // @todo prevent duplication based after
                        //    https://github.com/apollographql/apollo-feature-requests/issues/117
                        event.fingerprint = [
                            exception.message,
                        ];
                    }

                    return event;
                },
                dsn: environment.sentry.dsn,
                environment: environment.environment,
                defaultIntegrations: false,
                integrations: [
                    inboundFiltersIntegration(),
                    functionToStringIntegration(),
                    breadcrumbsIntegration({
                        console: false,
                    }),
                    globalHandlersIntegration(),
                    linkedErrorsIntegration(),
                    dedupeIntegration(),
                    httpContextIntegration(),
                    reportingObserverIntegration(),
                    extraErrorDataIntegration({
                        depth: objectDepth,
                    }),
                ],
                normalizeDepth: objectDepth,
            });
        }

        this.initialized = true;
    }

    private static modifyBreadcrumb(breadcrumb: Breadcrumb, hint: any): Breadcrumb | null {
        switch (breadcrumb.category) {
            case 'ui.click':
                return this.breadcrumbUiClick(breadcrumb, hint);
            case 'ui.input':
                return this.breadcrumbUiInput(breadcrumb, hint);
            case 'xhr':
                return this.breadcrumbXhr(breadcrumb, hint);
        }

        return breadcrumb;
    }

    private static breadcrumbUiClick(
        breadcrumb: Breadcrumb,
        {event}: {event: MouseEvent; name: 'click'},
    ): Breadcrumb | null {
        const message: Array<string> = [];

        const path = event.composedPath();
        message.push(this.pathToString(path));

        if (event.target instanceof HTMLElement) {
            message.push(event.target.innerText);
        }

        breadcrumb.message = message.map(m => m.trim()).join(' > ');

        return breadcrumb;
    }

    private static breadcrumbUiInput(breadcrumb: Breadcrumb, hint: any) {
        const target: HTMLElement = hint.event.target;

        const message = ['input'];

        const formControlName = target.getAttribute('formcontrolname');
        if (formControlName !== null) {
            message.push(`[formcontrolname=${formControlName}]`);
        }

        const placeholder = target.getAttribute('placeholder');
        if (placeholder !== null) {
            message.push(`[placeholder=${placeholder}]`);
        }

        breadcrumb.message = message.join(' ');

        return breadcrumb;
    }

    private static breadcrumbXhr(breadcrumb: Breadcrumb, {xhr}: {xhr: XMLHttpRequest}) {
        if (!xhr.responseURL.startsWith(environment.api)) {
            return breadcrumb;
        }

        const graphqlOperation = xhr.getResponseHeader('X-GraphQL-Operation');

        if (graphqlOperation !== null) {
            if (breadcrumb.data === undefined) {
                breadcrumb.data = {};
            }

            breadcrumb.data.graphqlOperation = graphqlOperation;
            breadcrumb.message = `GraphQL: ${graphqlOperation}`;
        }

        return breadcrumb;
    }

    private static pathToString(path: Array<any>): string {
        let result = '';
        for (let i = path.length - 1; i >= 0; i--) {
            const element = path[i];
            if (!(element instanceof HTMLElement)) {
                continue;
            }

            let elementInfo = element.tagName.toLowerCase();

            let classes = '';
            element.classList.forEach(c => {
                if (['cdk', 'mat', 'ng'].some(prefix => c.startsWith(prefix))) {
                    return;
                }

                if (classes.length > 0) {
                    classes += ' ';
                }

                classes += c;
            });
            if (classes.length > 0) {
                elementInfo += `.[${classes}]`;
            }

            const id = element.getAttribute('id');
            if (id !== null) {
                elementInfo += `#${id}`;
            }

            result += `${elementInfo}`;

            if (i > 0) {
                result += ' > ';
            }
        }

        return result;
    }
}
