import type {ErrorHandler} from '@angular/core';
import {Injectable} from '@angular/core';

import {Logger} from '../../shared/utils/logger.util';
import {objectContainsProperty} from '../../shared/utils/object.util';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {

    handleError(error: any) {
        if (error?.rejection?.name === 'ChunkLoadError') {
            window.location.reload();
            return;
        }

        const extractedError = Logger.extractError(error);

        if (extractedError === null) {
            return;
        }

        if (objectContainsProperty(extractedError, 'isUnexpected')
            && extractedError.isUnexpected === false) {
            return;
        }

        setTimeout(() => {
            // Delay this logging to push this log behind a potential manual Logger.error.
            // This is needed because sentry dedupes and this log usually is less specific.
            Logger.error({
                error,
                message: 'Angular ErrorHandler triggered',
            });
        }, 200);
    }
}
